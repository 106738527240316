import React, { useCallback, useEffect, useMemo, useState } from "react"
import Zoom from "../../components/zoom/zoom"
import { getBookingRequestData } from "../../utils/services/manageBookingServices"
import { useHistory, useLocation } from "react-router-dom"
import { ZoomMtg } from "@zoom/meetingsdk"
import { joinEpicboard } from "utils/services/epicRoomServices"
import { useDispatch } from "react-redux"
import {
    epicBoardUpdate,
    epicBoardUpdateSessionInfo,
    epicBoardUpdateTutor,
} from "store/actions/epicBoardActions"
import BrowserNotification from "../../components/browser-notifcation/BrowserNotification"
import useSessionExtension from "utils/hooks/useSessionExtension"
import { functions, firebaseAuth, database } from "../../utils/helper/firebase"
import moment from "moment"
import LoaderSvgIcon from "assets/images/svgIcons/LoaderSvgIcon"
import { useIsPhoneOrTablet } from "utils/hooks/useIsPhoneOrTablet"

const VendorPage = (): JSX.Element => {

    const isPhoneOrTablet = useIsPhoneOrTablet() || false;
    const [prevPath, setPrevPath] = useState(window.location.pathname);
    const location = useLocation()
    const history = useHistory()
    const getQueryParam = useCallback(
        (name: string) => {
            const urlParams = new URLSearchParams(location.search)
            return urlParams.get(name)
        },
        [location.search],
    )
    const bookingId: string = getQueryParam("bookingId") || ""
    const sessionId: string = getQueryParam("sessionId") || ""
    const userType: string = getQueryParam("userType") || "STUDENT"
    const studentName: string = getQueryParam("studentName") || "Unknown"
    const teacherName: string = getQueryParam("teacherName") || "Unknown"
    const platform: string | undefined = getQueryParam("platform") || undefined

    /**
     * listen for url change
     * and on change reload
     */
    useEffect(() => {
        const unlisten = history.listen((location, state) => {
            if (prevPath !== location.pathname) {
                window.location.reload()
            }
        })
        return () => {
            setTimeout(() => {
                unlisten()
            })
        }
    }, [])

    const userName = useMemo(() => {
        return userType.toUpperCase() === "TEACHER" ? teacherName : studentName
    }, [userType])

    const dispatch = useDispatch()
    const { sessionData, extensionTime, ExtensionComponent } = useSessionExtension({
        services: {
            functions: functions,
            auth: firebaseAuth,
            realtime: database,
        },
        sessionId: sessionId,
        bookingId: bookingId,
        userType: userType,
    })
    const endTime = useMemo(() => {
        if (!sessionData?.startTime) {
            return
        }
        return moment(sessionData?.startTime)
            .add(sessionData?.sessionLength, "minutes")
            .add(extensionTime || 0, "minutes")
    }, [sessionData, extensionTime])

    const isValidMeeting = useMemo(() => {
        return !!bookingId && !!sessionId && (endTime?.diff(moment(), "seconds") || 0) > 0
    }, [bookingId, sessionId, endTime])

    /**
     * initialize the room and populate it
     * with data
     */
    useEffect(() => {
        if (sessionId) {
            populateRoom(sessionId)
                .then(() => { })
                .catch(console.error)
        }
    }, [sessionId])
    /**
     * retrieve the session details
     */
    useEffect(() => {
        if (bookingId) {
            recreateSessionIfNotExistAlready(bookingId).catch(console.error)
        }
    }, [bookingId])

    /**
     * Close the meeting on destined time
     */
    useEffect(() => {
        if (!sessionId || !bookingId || !endTime) {
            return
        }
        const timer = setTimeout(() => {
            endMeeting().catch(console.error)
        }, endTime.diff(moment(), "milliseconds"))
        return () => {
            clearTimeout(timer) //clear all timers
        }
    }, [sessionId, bookingId, endTime])

    const populateRoom = useCallback(async (sessionId: string) => {
        const roomInformation = await joinEpicboard(sessionId)
        if (roomInformation.error) {
            alert(roomInformation.error)
            return
        }
        const item = { ...roomInformation.meta }
        dispatch(epicBoardUpdate({ epicBoard: item }))
        dispatch(epicBoardUpdateTutor({ tutorInfo: roomInformation.tutorInfo }))
        dispatch(epicBoardUpdateSessionInfo({ sessionInfo: roomInformation.sessionInfo }))
    }, [])

    const recreateSessionIfNotExistAlready = useCallback(async (bookingId: string) => {
        return await getBookingRequestData(bookingId).then(async ({ data }: any) => {
            return data
        })
    }, [])

    const endMeeting = useCallback(async () => {
        ZoomMtg.endMeeting({
            success: (d: any) => console.log("MKDEBUG: Meeting Ended: ", d),
            error: (e: any) => console.error("MKDEBUG: Error while ending the meeting: ", e),
        })
    }, [])

    if (isValidMeeting === true) {
        return (
            <>
                <ExtensionComponent />
                {sessionId ? (
                    <Zoom
                        sessionId={sessionId}
                        bookingId={bookingId}
                        userName={userName}
                        userType={userType}
                        platform={platform}
                    />
                ) : (
                    <div></div>
                )}
               { (!platform && isPhoneOrTablet) &&
                <BrowserNotification />
               }
            </>
        )
    }
    return (
        <div className="ColorClass">
            <div className="backDrop">
                <div className="wrapper">
                    <div className="backgroundWhite"></div>
                    <LoaderSvgIcon />
                </div>
            </div>
        </div>
    )
}

export default VendorPage
