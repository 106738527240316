import firebase from "firebase"
import { StepHandlerType, StepsAndHandlersMapType } from "../../../../../../utils/hooks/useStepwiseExecution";
import { SharedState } from "./AllStepsAndHandlers";

const getCustomToken: StepHandlerType = async (data: any, sharedState: SharedState, setSharedState) => {

    try {
        const getToken = firebase.functions().httpsCallable("users");
        const result: any = await getToken({ actionType: "GET_USER_CUSTOM_TOKEN", userId: sharedState?.authUser?.uid });
        // document.cookie = `token=${result.data};path=/;domain=${'.'+process.env.REACT_APP_APP_DOMAIN}`
        setSharedState((ps: SharedState) => {
            return {
                ...ps,
                customToken: result.data
            }
        });
        return {
            type: "success",
            data: {
                customToken: result.data
            }
        };
    } catch (e) {
        throw {
            type: "error",
            message: e.message,
            code: e.code
        };
    }

}


/**
 * all these functions depend on each, so you can just
 * random change their order unless you are sure
 * what you are doing
 */
export const stepsAndHandlers = [
    [
        getCustomToken
    ], // first step, get custom token
] as StepsAndHandlersMapType;

/**
 * this object contains messages of the above mentioned
 * steps
 */
export const stepsAndMessages = [
    "Redirecting to the lessons ..."
];