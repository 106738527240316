import { Modal } from "@material-ui/core"
import React from "react"
import { CloseIcon } from "../../../../components/icons/Icons"

interface Iprops {
    open: boolean
    close: Function
}

const NonCircumventionAgreementModal = ({ open, close }: Iprops): JSX.Element => {
    return (
        <Modal
            open={open}
            onClose={(): void => {
                close()
            }}
            className="add-card NCA-modal custom-modal"
        >
            <div className="custom-modal-body">
                <div className="custom-modal-content privacy-modal" >
                    <div className={`close-modal-button`}>
                        <CloseIcon
                            onClick={(): void => {
                                close()
                            }}
                        />
                    </div>
                    <>
                    <div className ="nonCircumventionAgreement">
                        <h1>Non-Circumvention Agreement</h1>
                        <br />
                        <p className="text">
                        We are so excited to match you with your tutor! We value and rely on our student-tutor relationships, and remind you that private exchanges of money off of the app are prohibited. Violations may result in a ban of your participation from the app, in addition to penalties of at least $1000, plus other damages. We trust that you will honor TCD’s Terms of Use and will inform us of any requests to circumvent the app or any other unauthorized conduct.  

                        </p>
                        </div>
                    </>
                </div>
            </div>
        </Modal>
    )
}

export default NonCircumventionAgreementModal
