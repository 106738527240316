import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    getPendingBookings,
    getUpcomingSessions,
    getPastSessions,
} from "../../../store/actions/sessionsActions"

import SwitchTabComponent from "../../../components/tabs/switch-tab/SwitchTabComponent"
import { makeStyles } from "@material-ui/core/styles"
import SessionCard from "./SessionCard"
import PastSessions from "./PastSessions"
import UpcomingSessions from "./UpcomingSessions"
import Grid from "@material-ui/core/Grid"
import BeatLoader from "react-spinners/BeatLoader"
import { navigatetoUrl } from "../../../utils/helper/helper"
import { applicationConstant } from "../../../utils/constant/applicationConstant"
import InterfaceConstant from "../../../utils/constant/interfaceConstant"
import { orderAndFilterUpcomingSessions } from "../../../utils/helper/session-utils"
import NoResultsCard from "../../../components/dashboard/NoResultsCard"
import { useHistory } from "react-router-dom"
import ToastNotification from "../../../components/modal/ToastNotification"
import { toastConstant } from "../../../store/constant"
import { Elements } from "@stripe/react-stripe-js"
import { stripe } from "../../../utils/helper/stripe-utils"

interface SessionPanelProps {
    index: any
}

const RequestTypes = {
    UPCOMING: "Upcoming",
    PENDING: "Accept / Reject",
    HISTORY: "Past Sessions",
}

function SessionTabPanel(props: SessionPanelProps) {
    const { index } = props
    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            <Elements stripe={stripe}>
                <SessionCard />
            </Elements>
        </div>
    )
}

const useStyles = makeStyles({
    gridwrap: {
        padding: "10px 0",
    },
    switchWidth: {
        flex: "0 1 30%",
    },
})

const MySessionPage = (props: any): JSX.Element => {
    const history = useHistory()
    const classes = useStyles()
    const sessiondata = useSelector((state: any) => state.session)
    const userType = useSelector((state: any) => state.auth.userAuthData.userType)
    const userId = useSelector((state: any) => state.auth.userAuthData.userId)
    const { message, show, isSuccess } = useSelector((state: any) => state.toast)
    const [tabValue, setTabValue] = useState(RequestTypes.PENDING)
    const isLoading = sessiondata.upcomingSessionsLoading
    const routeUrl: InterfaceConstant.IrouteUrl = applicationConstant.routeUrl
    const dashboardLabel: InterfaceConstant.IdashboardLabel = applicationConstant.dashboardLabel
    const pastSessionLoading = sessiondata.pastSessionsLoading
    const sortedUpcomingSessions = orderAndFilterUpcomingSessions(sessiondata.sessions)
    const dispatch = useDispatch()
    const epicBoard = useSelector((state: any) => state.epicBoardReducer.epicBoard)
    const counts = {
        [RequestTypes.PENDING]: sessiondata?.bookings?.length || 0,
        [RequestTypes.UPCOMING]: sortedUpcomingSessions?.length || 0,
        [RequestTypes.HISTORY]: sessiondata?.pastsessions?.sessions?.length || 0
    };
    const shouldDisplayCount = {
        [RequestTypes.PENDING]: true,
        [RequestTypes.UPCOMING]: true,
        [RequestTypes.HISTORY]: false
    }
    const handleChange = (newValue: string) => {
        setTabValue(newValue)
    }
    useEffect(() => {
        dispatch(getUpcomingSessions(userId))
        dispatch(getPendingBookings())
        if (tabValue === RequestTypes.HISTORY) {
            dispatch(getPastSessions(userId))
        }
    }, [tabValue])

    const navigatetoEpicboard = () => {
        navigatetoUrl(props.history, routeUrl.EPIC_BOARD_PAGE)
    }

    return (
        <div className="session-container">
            <Grid container justify="space-between" classes={{ root: classes.gridwrap }}>
                <Grid item className="session-heading-grid">
                    <h4 className="session-heading">My Sessions</h4>
                    {/* <h6 className="session-subheading">{`Total Requests: ${
                        tabValue === RequestTypes.PENDING
                            ? sessiondata.bookings
                                ? sessiondata.bookings.length
                                : ""
                            : tabValue === RequestTypes.UPCOMING
                            ? sessiondata.sessions
                                ? sortedUpcomingSessions.length
                                : ""
                            : tabValue === RequestTypes.HISTORY
                            ? sessiondata.pastsessions.sessions
                                ? sessiondata.pastsessions.sessions.length
                                : ""
                            : ""
                    }`}</h6> */}
                </Grid>
                <Grid item classes={{ item: classes.switchWidth }} className="session-tabs-grid">
                    <div className="my-sessions">
                    <SwitchTabComponent
                        title=""
                        options={Object.values(RequestTypes)}
                        selectedOption={tabValue}
                        counts={counts}
                        shouldDisplayCount={shouldDisplayCount}
                        onTabClick={handleChange}
                    />
                    </div>
                </Grid>
            </Grid>
            {tabValue === RequestTypes.PENDING && <SessionTabPanel index={0} />}

            {tabValue === RequestTypes.UPCOMING &&
                (isLoading ? (
                    <div className="float_auto">
                        <BeatLoader />
                    </div>
                ) : sortedUpcomingSessions.length ? (
                    sortedUpcomingSessions.map((item: any) => {
                        return (
                            <UpcomingSessions
                                {...item}
                                navigatetoEpicboard={navigatetoEpicboard}
                                key={item.id}
                            />
                        )
                    })
                ) : userType === "STUDENT" ? (
                    <NoResultsCard
                        titleText={dashboardLabel.NO_UPCOMING_CARD_TITLE}
                        subtitleText={dashboardLabel.NO_TUTORS_CARD_SUBTITLE_2}
                        buttonText={dashboardLabel.NO_TUTORS_CARD_BUTTON_TEXT}
                        buttonAction={() => navigatetoUrl(history, "/panel/search")}
                    />
                ) : (
                    <NoResultsCard
                        titleText={dashboardLabel.NO_UPCOMING_CARD_TITLE}
                        subtitleText={""}
                        noButton
                    />
                ))}

            {tabValue === RequestTypes.HISTORY && sessiondata.pastsessions.sessions ? (
                pastSessionLoading ? (
                    <div className="float_auto">
                        <BeatLoader />
                    </div>
                ) : sessiondata.pastsessions.sessions.length ? (
                    <PastSessions sessiondata={sessiondata.pastsessions.sessions} />
                ) : userType === "STUDENT" ? (
                    <NoResultsCard
                        titleText={"You have no past sessions... yet!"}
                        subtitleText={dashboardLabel.NO_TUTORS_CARD_SUBTITLE_2}
                        buttonText={dashboardLabel.NO_TUTORS_CARD_BUTTON_TEXT}
                        buttonAction={() => navigatetoUrl(history, "/panel/search")}
                    />
                ) : (
                    <NoResultsCard
                        titleText={"You have no past sessions... yet!"}
                        subtitleText={""}
                        noButton
                    />
                )
            ) : null}
            <ToastNotification
                message={message}
                isopen={show}
                isOpenAction={() => dispatch({ type: toastConstant.TOAST_HIDE })}
                styleClass={isSuccess ? "success-toast" : "error-toast"}
            />
        </div>
    )
}
export default MySessionPage
